import type { ReadonlyURLSearchParams } from 'next/navigation';

export function parseUrlQueryToRecord(
  query: ReadonlyURLSearchParams
): Record<string, string | string[] | undefined> {
  return Array.from(query.entries()).reduce<
    Record<string, string | string[] | undefined>
  >((obj, [key, value]) => {
    const lastTwoChars = key.slice(-2);
    // this expect query string arrays to be formatted such that params that  are part of an array
    // include [] at the end of the key e.g. filter[]=1&filter[]=2
    const keyIsArray = lastTwoChars === '[]';

    if (keyIsArray) {
      // get all but the last two characters
      key = key.slice(0, -2);
    }

    const accValue = obj[key];

    // add our new value to the array if it exists
    if (Array.isArray(accValue)) {
      accValue.push(value);
      return obj;
    }

    if (accValue === undefined) {
      // if our accumulator doesn't have a value then we need to set it based on wheter the key is
      // an array or not
      obj[key] = keyIsArray ? [value] : value;
      return obj;
    }

    return obj;
  }, {});
}
